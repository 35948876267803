import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        なにわ生まれなにわ育ちの関西人。関西大学総合情報学部を卒業後、2020年MONTAGEに入社。ファッション、ヘアカラー、メイクを嗜みます。入社を機に上京した後、地元大阪が恋しくなる。<br /><br />
        バンドはしてません。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
